<template>
  <div class="terms">
    <h1 class="title">隐私政策</h1>
    <p class="titleInfo">Privacy Policy</p>
    <p class="info">(Updated on NOV 15, 2021)</p>
    <h2 class="sTitle">特别提示：</h2>
    <h2 class="sTitle">Special Notice:</h2>
    <p class="s1">
      您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，Vancheen软件服务提供者香港维珍有限公司（以下简称“我们”或“Vancheen”）制定本《隐私政策》（下称“本政策”）并提醒您：
      <br /><br />Your trust is very important to us. We know the importance of
      personal information to you. We will take corresponding security
      protection measures in accordance with laws and regulations to try our
      best to protect your personal information. In view of this, Vancheen
      software service provider VEEGEN(HONGKONG)ENTERPRISE COMPANY LIMITED
      (hereinafter referred to as "we" or "Vancheen") formulates this Privacy
      Policy (hereinafter referred to as "this policy") and reminds you:
    </p>
    <p class="s1">
      经我们同意的基础上，您自愿申请成为Vancheen用户，接受Vancheen网上购物服务，同意享受平台商家向您推荐商品、提供服务、促进商品交易。
      您在使用Vancheen前应在我们经营的平台上完成授权登录，方可使用Vancheen各项产品及/或服务。
      本政策适用于VancheenH5/小程序、或其他关联公司提供的产品及/或服务（下称“Vancheen”），在点击同意本政策或使用Vancheen之前，请您务必仔细阅读（特别是加粗并下划线的内容）并透彻理解本政策，在确认充分理解并同意后使用相关产品功能。
      一旦您开始使用Vancheen，即表示您已充分理解并同意本政策。如对本政策内容有任何疑问、意见或建议，您可通过Vancheen提供的各种联系方式与我们联系。
      <br /><br />On the basis of our consent, you voluntarily apply to become a
      user of Vancheen, accept Vancheen online shopping service, and agree to
      enjoy the goods recommended, services provided and commodity transactions
      promoted by the platform merchants. You should complete real name
      authentication before you use Vancheen. This policy is applicable to
      products and / or services provided by Vancheen H5 / applet or other
      affiliated companies (hereinafter referred to as "Vancheen"). Please read
      it carefully (especially the bold and underlined contents) and thoroughly
      understand and confirm this policy before clicking to agree to this policy
      and use Vancheen or/and relevant product functions. Once you start using
      Vancheen, it means that you have fully understood and agreed to this
      policy. If you have any questions, opinions or suggestions about the
      content of this policy, you can contact us through various contact methods
      provided by Vancheen.
    </p>
    <h2 class="sTitle">第一部分 定义</h2>
    <h2 class="sTitle">Part I Definitions</h2>
    <p class="s1">
      一、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然
      人的各种信息。
      <br /><br />1、 The “personal information” here refers to all kinds of
      information recorded electronically or in other ways that can identify a
      specific natural person alone or in combination with other information.
    </p>
    <p class="s1">
      二、个人敏感信息：指包括身份证件号码、个人生物识别信息、银行账户、行踪轨迹、交易信息等。
      <br /><br />2、 The “personal sensitive information” here refers to
      personal information including ID card number, personal biometric
      information, bank account, whereabouts, transaction information, etc.
    </p>
    <p class="s1">
      三、信息删除：指在实现日常业务功能所涉及的系统中去除信息的行为，使其保持不可被检索、访问的状态。
      <br /><br />3、 The “information deletion” here refers to the act of
      removing information from the system involved in the realization of daily
      business functions, so that it can not be retrieved and accessed.
    </p>
    <p class="s1">
      四、儿童：指不满十四周岁的未成年人。
      <br /><br />4、 The “children” here refer to minors under the age of 14.
    </p>
    <p class="s1">
      五、关键公司：是指与我们之间存在直接或间接控制关系或其实际控制人相同或具有重大影响关系的企业。
      <br /><br />5、The “key company” here refers to an enterprise that has a
      direct or indirect control relationship with us or has the same or
      significant influence relationship with its actual controller.
    </p>
    <p class="s1">
      六、除另有约定外，本政策所用定义与《Vancheen个人用户服务协议》中的定义具有相同的涵义。
      <br /><br />6、 Unless otherwise agreed, the definitions used in this
      policy have the same meanings as those in the Vancheen Individual User
      Service Agreement.
    </p>
    <h2 class="sTitle">第二部分 隐私政策</h2>
    <h2 class="sTitle">Part II Privacy Policy</h2>
    <p class="s1">
      一、信息收集
      <br /><br />1、 Information Collection
      <br /><br />在您使用Vancheen的过程中，我们会按照如下方式收集您在使用Vancheen时主动提供或因为使用Vancheen而产生的信息，用以向您提供平台服务或优化我们的产品功能以及保障您的账户安全：
      <br /><br />In the process of using Vancheen, we will collect the
      information that you actively provide in the following ways, due to
      provide you with platform services or optimize our product functions and
      ensure the security of your account:
    </p>
    <p class="s1">
      1、用户登录：当您登录Vancheen平台时，为保护您Vancheen账户的安全，我们收集您的电子邮箱并通过验证码进行身份验证。若您不提供这类信息，您可能无法正常使用Vancheen平台功能。
      <br /><br />1. User login: when you log in to the Vancheen platform, in
      order to protect the security of your Vancheen account, you need to
      authenticate through the verification code of your email. If you do not
      provide such information, you may not be able to use our platform function
      normally.
    </p>
    <p class="s1">
      2、实名认证：按照国家法律法规的规定，使用Vancheen，您应当进行实名认证，我们将收集您的真实姓名、身份证信息、银行卡信息和联系地址。收集上述信息是为了验证您的主体身份，保障您的交易安全和资料安全。若不提供上述信息，您将不能完整使用Vancheen，由此产生的损失和后果由您本人承担。
      <br /><br />2. Real name authentication: According to the provisions of
      national laws and regulations, when using Vancheen, you should carry out
      real name authentication, and we will collect your real name, ID card
      information, bank card information and contact address. The above
      information is collected to verify your identity and ensure your
      transaction security and data security. In the process of real name
      authentication. If you do not provide the above information, you will not
      be able to use Vancheen completely, and the resulting losses and
      consequences shall be borne by yourself.
    </p>
    <p class="s1">
      3、交易信息：为了记录您的电子商务交易过程，维护消费者的合法权益，系统会自动生成订单信息，包括订单编号
      、订单生成时间、商品信息、支付信息等内容，以便帮助您完成商品交易。在此过程中，Vancheen有权基于对消息者个人信息安全保护的目的，对相关信息做脱敏处理。
      <br /><br />3. Transaction information: In order to record your e-commerce
      transaction process and safeguard the legitimate rights and interests of
      consumers, the system will automatically generate order information,
      including order number, order generation time, commodity information,
      payment information, etc. to help you complete the commodity transactions.
      During this process, Vancheen has the right to desensitize the relevant
      information for the purpose of protecting the personal information of the
      messenger.
    </p>

    <p class="s1">
      4、优化产品功能：当您使用Vancheen时，我们会收集您的设备信息（包括设备型号、操作系统版本等）和服务日志信息（包括您在Vancheen平台搜索或查看的信息、技术故障信息、登录IP地址、接入网络的方式类型和状态等。）上述信息为系统自动获取，收集上述信息是为了保障您正常使用Vancheen，维护Vancheen的正常运行，改进及优化Vancheen的产品体验以及保障您的账户安全。您理解并同意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息，不属于您的个人信息。
      <br /><br />4. Optimize product functions: When you using Vancheen, we
      will collect your device information (including device model, operating
      system version, etc.) and log information (including information you
      search or view on Vancheen platform, technical fault information, login IP
      address, access mode, type and status of network, etc.). The above
      information is automatically obtained by the system, the above information
      is collected to ensure your normal use of Vancheen, maintain the normal
      operation of Vancheen, improve and optimize Vancheen's product experience
      and ensure the security of your account. You understand and agree that
      separate equipment information and service log information are information
      that cannot identify a specific natural person and do not belong to your
      personal information.
    </p>
    <p class="s1">
      5、搜索：当您使用Vancheen平台的“搜索”功能时，我们会收集您的搜索记录。如您不使用“搜索”功能，我们将不会向您收集上述信息，且对您使用Vancheen平台无任何影响。
      <br /><br />5. Search: When you use the "search" function of Vancheen
      platform, we will collect your search records. If you do not use the
      search function, we will not collect the above information from you and
      will not have any impact on your use of the Vancheen platform.
    </p>
    <p class="s1">
      6、特别约定：您充分知晓，根据相关法律法规之规定，以下情形中，我们收集、使用您的个人信息无需征得您的授权同意：
      <br /><br />6. Special agreement: You fully understand that according to
      relevant laws and regulations, we do not need your authorization and
      consent to collect and use your personal information under the following
      circumstances: <br /><br />(1) 与中国国家安全及国防安全有关的；
      <br /><br />(1) Related to China's national security and national defense
      security; <br /><br />(2) 与公共安全、公共卫生、重大公共利益有关的；
      <br /><br />(2) Related to public safety, public health and major public
      interests; <br /><br />(3)
      与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的； <br /><br />(3)
      Related to judicial or administrative law enforcement such as criminal
      investigation, prosecution, trial and judgment execution; <br /><br />(4)
      出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      <br /><br />(4) For the purpose of safeguarding the life, property and
      other major legitimate rights and interests of the personal information
      subject or other individuals, but it is difficult to obtain your consent;
      <br /><br />(5) 所收集的个人信息是您自行向社会公众公开的； <br /><br />(5)
      The personal information collected is disclosed to the public by yourself;
      <br /><br />(6)
      从合法公开披露的信息中收集个人信息的，如合法的新闻报道，政府信息公开等渠道；
      <br /><br />(6) Collecting personal information from legally disclosed
      information, such as legal news reports, government information disclosure
      and other channels;
      <br /><br />(7)根据与您签订和履行相关协议或其他书面文件所必需的；
      <br /><br />(7) Necessary for signing and performing relevant agreements
      or other written documents with you; <br /><br />(8)
      用于维持Vancheen软件的安全稳定运行所必需的，例如发现、处置产品或技术故障；
      <br /><br />(8) Necessary for maintaining the safe and stable operation of
      Vancheen, such as finding and handling product or technical faults;
      <br /><br />(9) 为合法的新闻报道所必需的； <br /><br />(9) Necessary for
      legitimate news reporting; <br /><br />(10)
      学术研究机构基本公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      <br /><br />(10) When it is necessary for academic research institutions
      to carry out statistics or academic research in the basic public
      interests, and provide the results of academic research or description to
      the outside world, they de identify the personal information contained in
      the results; <br /><br />(11) 法律法规规定的其他情形。
      请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善Vancheen的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
      如我们停止运营Vancheen产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。
      <br /><br />(11) Other circumstances stipulated by laws and regulations.
      Please be aware that, according to applicable laws, if we take technical
      measures and other necessary measures to process personal information, so
      that the data receiver cannot re identify specific individuals and cannot
      recover, or we may conduct de identification research, statistical
      analysis and prediction on the collected information to improve Vancheen's
      content and layout, If we provide product or service support for business
      decisions and improve our products and services (including machine
      learning or model algorithm training using anonymous data), the use of
      such processed data does not need to be notified to you and approved by
      you. If we stop operating Vancheen products or services, we will stop
      collecting your personal information in time, notify you of the notice of
      stopping operation in the form of delivery or announcement one by one, and
      delete or anonymize the personal information related to the closed
      business held by us.
    </p>
    <h2 class="sTitle">二、信息存储</h2>
    <h2 class="sTitle">2、 Information storage</h2>
    <p class="s1">
      1、信息存储的地点：我们会按照中国法律法规规定，将境内收集的用户个人信息存储于中国境内。
      <br /><br />1. Location of information storage: We will store the user's
      personal information collected in China in accordance with Chinese laws
      and regulations.
    </p>
    <h2 class="sTitle">三、信息使用</h2>
    <h2 class="sTitle">3、 Information use</h2>
    <p class="s1">
      1、向您提供的Vancheen服务和功能（包括但不限于如下功能）：
      <br /><br />1. Vancheen services and functions provided to you (including
      but not limited to the following functions): <br /><br />(1)
      使用您的电子邮箱登录和使用Vancheen； <br /><br />(1) Use your email to log
      in and use Vancheen; <br /><br />(2)
      使用您的头像、昵称等，以便您授权的第三方向您提供商品交易支持服务。
      <br /><br />(2) Use your avatar, nickname, etc. so that your authorized
      third parties can provide you with commodity trading support services.
      <br /><br />2、为提高您使用Vancheen平台的安全性，确保操作环境安全与识别用户账户异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或Vancheen相关协议规则的情况。我们可能使用或整合您的信息、交易信息、设备信息、有关网络日志等，综合判断您的Vancheen账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      <br /><br />2. In order to improve the security of Vancheen, and ensure
      the security of the operating environment and identify the abnormal status
      of user accounts, protect the personal and property security of you or
      other users or the public from infringement, for better preventing from
      phishing websites, fraud, network vulnerabilities, computer viruses,
      network attacks, network intrusion and other security risks, more
      accurately identify violations of laws and regulations or Vancheen's
      relevant agreement rules. We may use or integrate your information,
      transaction information, equipment information, relevant network logs,
      etc., comprehensively judge your Vancheen account and transaction risks,
      conduct identity verification, detect and prevent security events, and
      take necessary recording, audit, analysis and disposal measures according
      to law.
      <br /><br />3、我们可能会对收集的信息进行去标识化的研究、统计分析和预测，用于改善Vancheen平台的内容和布局，为商业决策提供数据支撑，以及改进Vancheen软件功能（例如使用匿名数据进行机器学习或模型算法训练）。
      <br /><br />3. We may conduct de identification research, statistical
      analysis and prediction on the collected information to improve the
      content and layout of Vancheen, providing data support for business
      decisions, and improve the functions (such as machine learning or model
      algorithm training using anonymous data).
    </p>
    <h2 class="sTitle">四、信息共享</h2>
    <h2 class="sTitle">4、 Information sharing</h2>
    <p class="s1">
      （一）共享
      <br /><br />(1) Share
      <br /><br />我们不会与Vancheen软件以外的第三方公司、组织和个人共享您的个人信息，但以下情况除外：
      <br /><br />We will not share your personal information with third-party
      companies, organizations and individuals, except in the following cases:
      <br /><br />1、在法定情形下的共享：我们可能会根据法律规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息；
      <br /><br />a. Sharing under legal circumstances: We may share your
      personal information according to legal provisions, litigation and dispute
      resolution needs, or according to the requirements of administrative and
      judicial organs;
      <br /><br />2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息；
      <br /><br />b. Sharing with explicit consent: After obtaining your
      explicit consent, we will share your personal information with other
      parties;
      <br /><br />3、与关联公司间共享：为便于我们基于Vancheen账户向您提供产品或服务，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司以改变个人信息的使用及处理目的，将再次征求您的授权同意；
      <br /><br />c. Sharing with affiliated companies: In order for us to
      provide products or services to you based on Vancheen account, your
      personal information may be shared with our affiliated companies and / or
      their designated service providers. We will only share the necessary
      personal information and are bound by the purposes stated in this privacy
      policy. If we share your personal sensitive information or affiliated
      companies to change the purpose of using and processing your personal
      information, we will ask for your authorization and consent again
      <br /><br />4、与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私政策声明的合法、正当、必要、特定、明确的目的共享你的信息，且不得将此信息用于其他任何目的；
      <br /><br />d. Sharing with authorized partners: We may entrust authorized
      partners to provide you with certain services or perform functions on our
      behalf. We will only share your information for the legal, legitimate,
      necessary, specific and clear purposes of this privacy policy statement,
      and shall not use this information for any other purpose;
      <br /><br />5、学术研究或符合法律、法规的规定而共享等。
      我们会与其约定严格的数据保护措施，令其按照我们的说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。
      <br /><br />e. Academic research or sharing in accordance with laws and
      regulations. We will agree with them on strict data protection measures to
      enable them to process personal information in accordance with our
      instructions, this Privacy Policy and any other relevant confidentiality
      and security measures. <br /><br />（二）转让 <br /><br />（2） Transfer
      <br /><br />我们不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：
      We will not transfer your personal information to any company,
      organization or individual, except for the following circumstances:
      <br /><br />1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
      <br /><br />2. Transfer with explicit consent: after obtaining your
      explicit consent, we will transfer your personal information to other
      parties;
      <br /><br />2、在Vancheen软件发生合作、联营、合并、收购或资产转让等情况，或其他破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束。否则，我们将要求该公司、组织和个人重新向您征求授权同意。
      <br /><br />2. In case of cooperation, joint venture, merger, acquisition
      or asset transfer of Vancheen, or other bankruptcy liquidation situations,
      if personal information transfer is involved, we will require the new
      companies and organizations which holding your personal information should
      be continue to be bound by this policy. Otherwise, we will ask the
      company, organization and individual to ask you for authorization consent
      again. <br /><br />（三）公开披露 <br /><br />（3） Public disclosure
      <br /><br />我们仅会在以下情况下，公开披露您的个人信息： <br /><br />We
      will only publicly disclose your personal information under the following
      circumstances:
      <br /><br />1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
      <br /><br />1. We may publicly disclose your personal information with
      your express consent or based on your active choice;
      <br /><br />2、如果我们确定您出现违反法律法规或严重违反Vancheen相关协议及规则的情况，或为保护Vancheen平台用户或公众的人身财产安全免遭侵害，我们可能依据中国法律法规或征得您同意的情况下披露您的个人信息，包括相关违规行为以及Vancheen平台已对您采取的措施。
      <br /><br />2. If we determine that you have violated laws and regulations
      or seriously violated Vancheen's relevant agreements and rules, or in
      order to protect the personal and property safety of Vancheen users or the
      public from infringement, we may disclose your personal information in
      accordance with Chinese laws and regulations or with your consent,
      including relevant violations and the measures taken by Vancheen platform
      against you.
      <br /><br />（四）共享、转让、公开披露个人信息，事先征得授权同意的例外。
      <br /><br />（4） The sharing, transfer and public disclosure of personal
      information shall be subject to the exception of prior authorization and
      consent.
      <br /><br />以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
      <br /><br />In the following cases, your authorization and consent are not
      required for sharing, transferring and public disclosure of your personal
      information: <br /><br />1、与中国国家安全及国防安全有关的；
      <br /><br />1. Related to China's national security and national defense
      security; <br /><br />2、与公共安全、公共卫生、重大公共利益有关的；
      <br /><br />2. Related to public safety, public health and major public
      interests;
      <br /><br />3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；
      <br /><br />3. Related to judicial or administrative law enforcement such
      as criminal investigation, prosecution, trial and judgment execution;
      <br /><br />4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      <br /><br />4. For the purpose of safeguarding your or other personal
      life, property and other major legitimate rights and interests, but it is
      difficult to obtain my consent;
      <br /><br />5、您自行向社会公众公开的个人信息； <br /><br />5. Your
      personal information disclosed to the public by yourself;
      <br /><br />6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
      您理解并同意，根据法律规定，共享、转让经过去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据保存及处理将无需另外向您通知并征得您的同意。
      <br /><br />6. Collecting personal information from legally disclosed
      information, such as legal news reports, government information disclosure
      and other channels. Please be aware that according to applicable laws, if
      we take technical and other necessary measures to process personal
      information, so that the data receiver cannot re identify specific
      individuals and cannot recover, the sharing, transfer and public
      disclosure of such processed data do not need to be notified to you and
      obtain your consent. You understand and agree that, according to the law,
      sharing and transferring personal information that has been de identified,
      and ensuring that the data receiver cannot recover and re identify the
      subject of personal information, does not belong to the external sharing,
      transfer and public disclosure of personal information, and the
      preservation and processing of such data will not need to be notified to
      you and obtain your consent.
    </p>
    <h2 class="sTitle">五、信息保护</h2>
    <h2 class="sTitle">5、 Information protection</h2>
    <p class="s1">
      (1)
      我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。目前Vancheen
      APP和服务器使用HTTPS协议进行通信，用户敏感信息后台使用安全的意向散列算法并存储以确保信息不可逆，敏感信息前端展示时我们进行脱敏展示。我们也有一套数据访问控制体系确保只有授权的人才能访问个人信息，我们有建立完整的入侵监控和防御体系及时对可能的攻击做出响应。
      <br /><br />(1) We have taken reasonable and feasible security measures in
      line with industry standards to protect your information and prevent
      unauthorized access, public disclosure, use, modification, damage or loss
      of personal information. At present, Vancheen APP and server use HTTPS
      protocol for communication. The background of user sensitive information
      uses a safe intention hash algorithm and stores it to ensure that the
      information is irreversible. We desensitize the front-end display of
      sensitive information. We also have a set of data access control system to
      ensure that only authorized people can access personal information. We
      have established a complete intrusion monitoring and defense system to
      respond to possible attacks in time. <br /><br />(2)
      我们有完善的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性，我们也会定期举办信息安全培训课程加强员工的安全意识。
      <br /><br />(2) We have a perfect data security management system to
      improve the security of the whole system from the aspects of organization
      construction, system design, personnel management and product technology.
      We will also regularly hold information security training courses to
      strengthen the security awareness of employees. <br /><br />(3)
      我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。
      <br /><br />(3) We will take reasonable and feasible measures to try our
      best to avoid collecting irrelevant personal information. We will only
      retain your personal information for the period required to achieve the
      purpose stated in this policy, unless there is a mandatory retention
      requirement by law. For example, the e-commerce law of the people's
      Republic of China requires that the storage time of commodity and service
      information and transaction information shall not be less than three years
      from the date of completion of the transaction. <br /><br />(4)
      您在使用Vancheen时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您上传的头像。请您更加谨慎地考虑，在使用Vancheen时是否能共享或公开分享相关信息。
      <br /><br />(4) The information you voluntarily share or even publicly
      share when using Vancheen may involve your or others' personal information
      or even personal sensitive information, such as your uploaded faces.
      Please consider more carefully whether you can share or publicly share
      relevant information when using Vancheen. <br /><br />(5)
      请使用复杂密码，协助我们保证您的账户安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术和管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      <br /><br />(5) Please use a complex password to help us ensure the
      security of your account. We will try our best to ensure the security of
      any information you send us. If our physical, technical and management
      protection facilities are damaged, resulting in unauthorized access,
      public disclosure, tampering or destruction of information, resulting in
      damage to your legitimate rights and interests, we will bear corresponding
      legal responsibilities. <br /><br />(6)
      我们会定期对对外提供的平台功能进行安全测试并及时修复发现的安全问题。
      <br /><br />(6) We will regularly conduct security tests on the platform
      functions provided externally and timely repair the security problems
      found. <br /><br />(7)
      在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施，您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照中国监管部门要求，上报个人信息安全事件的处置情况。
      <br /><br />(7) After an unfortunate personal information security
      incident occurs, we will inform you of the basic situation and possible
      impact of the security incident, the disposal measures we have taken or
      will take, the suggestions you can independently prevent and reduce the
      risk, the remedial measures for you, etc. in accordance with the
      requirements of laws and regulations. We will inform you about the event
      by email. If it is difficult to inform the personal information subject
      one by one, we will make an announcement in a reasonable and effective
      way. At the same time, we will also report the disposal of personal
      information security incidents in accordance with the requirements of
      Chinese regulatory authorities.
    </p>
    <h2 class="sTitle">六、信息管理</h2>
    <h2 class="sTitle">6、 Information management</h2>
    <p class="s1">
      您可以通过以下方式访问及管理您的信息：
      <br /><br />You can access and manage your information in the following
      ways: <br /><br />(1)查询您的信息 <br /><br />(1) Query your information
      <br /><br />您有权查询您的信息。您可以通过以下方式自行进行：
      <br /><br />You have the right to inquire your information. You can do it
      yourself in the following ways: <br /><br />a.用户信息 <br /><br />a. User
      information <br /><br />您可以通过Vancheen平台【我】修改头像、昵称。
      <br /><br />You can modify background photo, nickname through [Me].
      <br /><br />b.购物订单 <br /><br />b.Purchase order
      <br /><br />您可以通过【用户】-【我的订单】，查询买家订单（全部订单、待发货、待收货、已完成）
      You can click [user - buyer order] to query the buyer's order, including
      all of the orders, pending shipment, pending receipt and completed.
      <br /><br />2、更正或补充您的信息 <br /><br />2. Correct or supplement
      your information
      <br /><br />当您发现我们处理的关于您的信息有错误时，您有权要求我们做出更正或补充。您可以通过向我们的帮助中心发送电子邮件的方式提出更正或补充申请。
      <br /><br />When you find that there are errors in the information we
      handle about you, you have the right to ask us to make corrections or
      supplements. You can apply for corrections or supplements by sending an
      email to our help center. <br /><br />3、响应您的上述请求 <br /><br />3.
      In response to your above request
      <br /><br />为保障安全，您可能需要提供书面请示，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15天内做出答复。
      对于您的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
      <br /><br />To ensure your safety, you may need to provide written
      instructions or prove your identity in other ways. We may ask you to
      verify your identity before processing your request. We will reply within
      15 days. In principle, we will not charge for your reasonable request, but
      we will charge a certain fee for repeated requests that exceed the
      reasonable limit. We may refuse requests that are not directly related to
      your identity, unreasonably duplicate information, or require too many
      technical means (e.g., need to develop new systems or fundamentally change
      current practices), pose risks to the legitimate rights and interests of
      others, or are impractical.
      <br /><br />在以下情形中，按照中国法律法规要求，我们将无法响应您的请求：
      <br /><br />Under the following circumstances, we will not be able to
      respond to your request in accordance with Chinese laws and regulations:
      <br /><br />(1) 与中国国家安全及国防安全有关的； <br /><br />(2)
      与公共安全、公共卫生、重大公共利益有关的； <br /><br />(3)
      与犯罪侦查、起诉、审判和执行判决等有关的； <br /><br />(4)
      有充分证据表明个人信息主体存在主观恶意或滥用权利的； <br /><br />(5)
      响应您的请求将导致您的其他个人、组织的合法权益受到严重损害的；
      <br /><br />(6) 涉及商业秘密的。 <br /><br />(1) Related to China's
      national security and national defense security; <br /><br />(2) Related
      to public safety, public health and major public interests;
      <br /><br />(3) Related to criminal investigation, prosecution, trial and
      execution of judgment; <br /><br />(4) There is sufficient evidence to
      show that the subject of personal information has subjective malice or
      abuse of rights; <br /><br />(5) Responding to your request will cause
      serious damage to the legitimate rights and interests of your other
      individuals and organizations; <br /><br />(6) Involving trade secrets.
    </p>
    <h2 class="sTitle">七、未成年人保护</h2>
    <h2 class="sTitle">7、 Protection of minors</h2>
    <p class="s1">
      1、在电子商务活动中我们推定您是具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或监护人仔细阅读本隐私权政策。
      <br /><br />1. In e-commerce activities, we presume that you have
      corresponding civil capacity. If you are a minor, we ask you to ask your
      parents or guardians to read this privacy policy carefully.
      <br /><br />2、我们只对在中国或您所在国的法律法规定义下的成年人开放成为Vancheen网店店主的权限。作为父母或监护人，您应知悉并引导您身边的未成年人切勿使用、共享、转让您的Vancheen账户信息和使用权限。
      <br /><br />2. We are only open to adults under the laws and regulations
      of China or your country to become the owner of Vancheen online store. As
      a parent or guardian, you should know and guide the minors around you not
      to use, share or transfer your Vancheen account information and
      permission.
    </p>
    <h2 class="sTitle">八、本隐私权政策如何更新</h2>
    <h2 class="sTitle">8、 How is this Privacy Policy updated</h2>
    <p class="s1">
      我们的隐私权政策可能变更。
      <br /><br />Our privacy policy may change.
      <br /><br />未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。我们会在专门页面上发布对隐私权政策所做的任何变更。
      <br /><br />Without your express consent, we will not limit your rights
      under this privacy policy. We will post any changes to the privacy policy
      on a special page.
      <br /><br />对于重大变更，我们还会提供更为显著的通知（包括平台公示或向您提供弹窗信息）。
      <br /><br />For major changes, we will also provide more significant
      notices, including platform publicity or pop-up message.
      <br /><br />本政策所指的重大变更包括做不限于： <br /><br />Major changes
      referred to in this policy include but are not limited to:
      <br /><br />1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      <br /><br />1. Significant changes have taken place in our service model.
      Such as the purpose of processing personal information, the type of
      personal information processed, the use of personal information, etc;
      <br /><br />2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
      <br /><br />2. We have undergone major changes in control and so on. Such
      as the change of information controller caused by merger and
      reorganization;
      <br /><br />3、个人信息共享、转让或公开披露的主要对象发生变化；
      <br /><br />3. The main objects of personal information sharing, transfer
      or public disclosure have changed;
      <br /><br />4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
      <br /><br />4. Significant changes have taken place in your right to
      participate in personal information processing and the way in which you
      exercise it;
      <br /><br />5、我们负责处理个人信息安全的责任部门、联系方式及投诉渠道发生变化。
      <br /><br />5. Our responsible department responsible for handling
      personal information security, contact information and complaint channels
      have changed.
    </p>
    <h2 class="sTitle">九、联系我们</h2>
    <h2 class="sTitle">9、 Contact us</h2>
    <p class="s1">
      帮助中心邮箱：service@vancheen.com
      <br /><br />Help Center Email: service@vancheen.com
      <br /><br />我们将在15天内回复您的请求。 <br /><br />We will reply to your
      request within 15 days.
    </p>
    <h2 class="sTitle">十、其他</h2>
    <h2 class="sTitle">10、 Other</h2>
    <p class="s1">
      (1) 条款的独立性
      <br /><br />(1) The independence of clauses
      <br /><br />如果根据适用的中国法律认定本政策中的任何条款或者任何条款中的任何部分无效、违法或者不具有可执行性，这种无效、违法或者不具有可执行性不影响本政策中的任何其它条款或者这些条款中的任何其他部分的效力。
      <br /><br />If any provision of this policy or any part thereof is found
      to be invalid, illegal or unenforceable in accordance with applicable
      Chinese laws, such invalidity, illegality or unenforceability shall not
      affect the effectiveness of any other provision of this policy or any
      other part thereof. <br /><br />(2) 生效时间 <br /><br />(2) Effective
      time <br /><br />本政策于2021年10月1日进行公示，自2021年10月7日起生效。
      <br /><br />This policy will be publicized on October 1, 2021 and will
      take effect from October 7, 2021.
    </p>
    <div class="agreeBox" @click="gotoTerm">
      <div class="agree">{{ $t.meta.Agreement }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Privacy",
  data() {
    return {};
  },
  created() {
    const { headerHide } = this.$route.query;
    if (headerHide) {
      this.headerHide = JSON.parse(headerHide);
      if (this.headerHide) {
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "none";
      }
    }
  },
  methods: {
    gotoTerm() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "terms/Agreement",
        name: "Agreement",
        query: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.terms {
  width: 90%;
  margin: 0 auto;
  * {
    font-size: 11px;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 13px;
    margin-top: 5%;
  }
  .titleInfo {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  .info {
    width: 100%;
    text-align: center;
    margin: 25px 0;
  }
  .s1 {
    text-align: left;
    margin: 25px 0;
  }
  .sTitle {
    text-align: left;
    margin: 25px 0;
  }
}
.agreeBox {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding-top: 3%;
  padding-bottom: calc(3% + env(safe-area-inset-bottom));
  text-align: center;
  border-top: 1px solid #e5e5e5;
  .agree {
    width: 80%;
    margin: auto;
    border-radius: 3rem;
    padding: 0.8rem 0;
    text-align: center;
    background: linear-gradient(left, #71d283, #01aaa3);
    color: #ffffff;
    font-size: 1rem;
    line-height: 1;
  }
}
</style>
