<template>
  <div class="regInfoBox">
    <auth-logo />
    <!-- 提交 -->
    <div class="formBox">
      <div class="inputBox lockBox">
        <input
          class="username"
          v-model="email"
          type="text"
          :placeholder="$t.auth.inputEmail"
          disabled="true"
        />
      </div>
      <div class="inputBox">
        <input
          class="password"
          v-model="passWord"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$t.auth.inputPaw"
        />
        <van-icon
          name="eye-o"
          v-if="showPassword"
          @click="showPassword = !showPassword"
        />
        <van-icon
          name="closed-eye"
          v-else
          @click="showPassword = !showPassword"
        />
      </div>
      <p class="inputInfo">{{ $t.auth.pawInfo }}</p>
      <div class="inputBox">
        <input
          class="username"
          v-model="invCode"
          type="text"
          :placeholder="$t.auth.inputInviteCode"
        />
      </div>
      <div class="sub" @click="sub">{{ $t.confirm }}</div>
      <div class="next" @click="onlogin">{{ $t.auth.backLogin }}</div>
    </div>
  </div>
</template>
<script>
import AuthLogo from "./components/AuthLogo";
import { SET_AUTH } from "@/store/mutation-types";
import { mapMutations } from "vuex";
import Vue from "vue";
import { Icon } from "vant";

Vue.use(Icon);

export default {
  name: "RegInfo",
  components: {
    AuthLogo,
  },
  data() {
    return {
      passWord: "",
      authCode: "", //验证码
      email: "", //邮箱
      invCode: "", //邀请码
      showPassword: false,
    };
  },
  created() {
    const query = this.$route.query;
    if (query.AuthCode) {
      this.authCode = query.AuthCode;
      this.email = query.Email;
    }
    if (query.InviteCode) {
      this.invCode = query.InviteCode;
    }
  },
  methods: {
    onlogin() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "auth/login",
        name: "Login",
        query: [],
      });
    },
    sub() {
      const reg =
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*,.])[0-9a-zA-Z!@#$%^&*,.]{6,20}$/;
      const passWord = this.passWord;
      const userName = this.email;
      if (passWord == "") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
          titleMsg: this.$t.auth.correctError,
          type: "danger",
        });
        return false;
      } else if (userName == "") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
          titleMsg: this.$t.auth.pleaseEnterEmail,
          type: "danger",
        });
        return false;
      } else {
        this.registerUser();
      }
    },
    //注册b用户
    registerUser() {
      const passWord = this.passWord;
      const userName = this.email;
      const invCode = this.invCode;
      let param = {
        PassWord: passWord,
        UserName: userName,
        AccountType: "B",
        FReferralCode: invCode,
      };
      this.$api.login
        .registerUser(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: this.$t.auth.registerSuccess,
            type: "success",
          });
          this.SET_AUTH(res.data);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.userToken, {
            token: res.data.Ticket,
          });
          this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
            isParams: false,
            path: "user/userManage",
            name: "UserManage",
            query: [],
          });

          // Dialog.confirm({
          //   message: this.$t.auth.registerInfo,
          //   className: "showDialogClass"
          // })
          //   .then(() => {
          //     window.location.href =
          //       "http://download.vancheen.com/AppDownload/B_AppDownload";
          //   })
          //   .catch(() => {
          //     window.location.href = "http://www.vancheen.com/";
          //   });
        })
        .catch((err) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: err.message,
            type: "danger",
          });
        });
    },
    ...mapMutations([SET_AUTH]),
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.regInfoBox {
  position: relative;
  min-height: 100vh;
  width: 100%;
  // background-color: red;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, #84cd8c),
    color-stop(100%, #4ca7a3)
  );
  background: gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, #84cd8c),
    color-stop(100%, #4ca7a3)
  );
  .formBox {
    width: 85%;
    position: relative;
    margin: 0 auto;
    margin-top: 2rem;
    .lockBox {
      background: rgb(0, 0, 0, 0.3);
      border-radius: 100px;
    }
    .inputBox {
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      width: 100%;
      @include publicFlex;
      .username,
      .password {
        background: none;
        outline: none;
        border: none;
        -webkit-appearance: none; /*去除系统默认的样式*/
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 点击高亮的颜色*/
        color: #f3faf8;
        font-size: 0.8rem;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border: 0.1rem solid #b9e1da;
        border-radius: 100px;
        padding: 0 1.5rem;
        box-sizing: border-box;
        &::placeholder {
          color: #f3faf8;
          font-size: 0.8rem;
        }
        &::-webkit-input-placeholder {
          color: #f3faf8;
          font-size: 0.8rem;
        }
      }
      .getCode,
      .codeInfo {
        outline: none;
        border: 0;
        height: 50px;
        width: 185px;
        background-color: #ffffff;
        color: #6dc093;
        font-size: 0.4rem;
        text-align: center;
        border-radius: 80px;
        overflow: hidden;
        margin-left: 5%;
      }
      .codeInfo {
        font-size: 1rem;
        line-height: 50px;
        background-color: #dcdcdc;
        color: #ffffff;
      }
      .van-icon {
        position: absolute;
        right: 5%;
        color: #ffffff;
      }
    }
    .inputInfo {
      font-size: 0.8rem;
      color: #f3faf8;
      padding: 0 30px;
      box-sizing: border-box;
    }
    .next {
      margin-top: 20px;
      width: 100%;
      border-radius: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
    }
    .sub {
      margin-top: 20px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      border-radius: 50px;
      color: #43cd9a;
      text-align: center;
      font-size: 1rem;
      background-color: #ffffff;
      font-weight: bold;
    }
  }
}
</style>
