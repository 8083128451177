import Vue from 'vue'
import { ImagePreview } from 'vant'
Vue.use(ImagePreview)
// 预览图片
export const picturePreview = (params) => {
  const { pictureList, startPosition } = params;
  let newPictureList = pictureList.map((item) => {
    return formatImageUrl(item);
  });
  ImagePreview({ images: newPictureList, closeable: true, startPosition: (startPosition ? startPosition : 0) });
};
// 解析url
export const formatImageUrl = url => {
  const imageUrl = process.env.VUE_APP_BASH_IMAGE_URL
  const bashImageUrl = imageUrl
  if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
    return url
  }
  if (url.indexOf('/') === 0) {
    return bashImageUrl + url.slice(1)
  }
  return bashImageUrl + url
}
export default {
  picturePreview
}
