<template>
  <div class="track">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="moreOnLoad"
    >
      <ul class="list">
        <li
          class="itemBox"
          v-for="(item, index) in footmarkByPageData"
          :key="index"
        >
          <p class="date">{{ item.ReadTime }}</p>
          <div
            class="productItem"
            @click="getProductDetails(item.ProductNumber, item.ShopId)"
          >
            <figure class="productLogo">
              <van-image
                class="productLogoIcon"
                :src="item.ProductPhoto | imgUrlFilter"
              >
                <template v-slot:error>
                  <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                </template>
              </van-image>
            </figure>
            <section class="productInfo">
              <div class="productTitle">
                <div class="discount" v-if="item.IsCouponProduct">
                  {{ $t.discount }}
                </div>
                <div class="brand" v-if="item.IsBrandProduct">
                  {{ $t.brand }}
                </div>
                <div class="attestation" v-if="item.IsAttestationProduct">
                  {{ $t.attestation }}
                </div>
                <span class="name">{{ item.ProductName }}</span>
              </div>
              <div class="productPrice">
                <span class="productPriceTitle">{{ $t.sellingPrice }}：</span>
                <!-- <span class="productPriceNum">$38.80~75.20</span> -->
                <span class="productPriceNum"
                  >${{ Math.round(item.MinPrice * 100) / 100 }}</span
                >
                <!-- <span class="productPriceNum" v-else>${{item.MinPrice}}{{$t.up}}</span> -->
              </div>
            </section>
          </div>
        </li>
      </ul>
      <placeholder v-if="isPlaceholder"></placeholder>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";
import { List, Image } from "vant";
import Placeholder from "@/components/common/Placeholder";
Vue.use(List).use(Image);
export default {
  name: "Track",
  components: {
    Placeholder,
  },
  data() {
    return {
      footmarkByPageData: [],
      pageIndex: 1,
      finished: false,
      loading: true,
      isfirst: true,
      isPlaceholder: false,
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    },
  },
  mounted() {
    this.footmarkByPage();
  },
  methods: {
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    // 获取足迹
    footmarkByPage() {
      this.$commonMethod.showLoading();
      let param = {
        PageIndex: this.pageIndex,
      };
      this.$api.follow
        .footmarkByPage(param)
        .then((res) => {
          // this.isfirst = false
          this.loading = false;
          console.log(res);
          if (res.data == null || res.data === "" || res.data.length === 0) {
            this.isPlaceholder = true;
          } else {
            this.footmarkByPageData = res.data;
            this.isPlaceholder = false;
          }
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          // this.isfirst = false
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      // console.log("这是isfirst" + this.isfirst);
    },
    moreOnLoad() {
      // console.log('加载更多')
      // this.loading = false
      // this.finished = false
      // if (this.isfirst) return
      console.log("执行加载更多");
      let param = {
        PageIndex: this.pageIndex + 1,
      };
      this.loading = true;
      this.$api.follow
        .footmarkByPage(param)
        .then((res) => {
          console.log(res);
          const data = res.data;
          if (data == null || data === "" || data.length === 0) {
            this.loading = false;
            this.finished = true;
            // console.log('无数据')
          } else {
            this.footmarkByPageData = this.footmarkByPageData.concat(data);
            this.pageIndex = this.pageIndex + 1;
            this.loading = false;
            this.finished = false;
            // console.log('有数据')
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";

@mixin liItem {
  align-items: center;
  -webkit-align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 17px 15px;
  border-radius: 8px;
  @include publicFlex;
}
.track {
  width: 100%;
  .list {
    margin: 0 auto;
    list-style: none;

    width: 92%;
    .itemBox {
      .date {
        padding: 0;
        margin: 15px 0 5px 0;
        font-size: 0.8rem;
      }
      .productItem {
        border-top: 1px solid #f6f6f6;
        @include liItem;
        &::nth-child(1) {
          border-top: none;
        }
        .productLogo {
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 15px;
          margin-left: 0;
          @include publicFlex;
          .productLogoIcon {
            width: 75px;
            height: 75px;
            object-fit: cover;
            -webkit-object-fit: cover;
            .ImgIcon {
              width: 75px;
              height: 75px;
              object-fit: cover;
            }
            ::v-deep .van-image__img {
              width: 75px;
              height: 75px;
              position: relative;
              object-fit: cover;
            }
            ::v-deep .van-image__error {
              width: 75px;
              height: 75px;
              position: relative;
              object-fit: cover;
            }
          }
        }
        .productInfo {
          flex: 1;
          -webkit-flex: 1;
          min-height: 75px;
          display: flex;
          -webkit-display: flex;
          flex-direction: column;
          -webkit-flex-direction: column;
          justify-content: space-between;
          -webkit-justify-content: space-between;
          .productTitle {
            color: #000000;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
            white-space: normal;
            .discount {
              vertical-align: middle;
              display: inline-block;
              background: red;
              color: white;
              padding: 0px 2px;
              border-radius: 2px;
              font-size: 10px;
              margin-right: 5px;
            }
            .brand {
              vertical-align: middle;
              display: inline-block;
              background: #e6a23c;
              color: white;
              padding: 0px 2px;
              border-radius: 2px;
              font-size: 10px;
              margin-right: 5px;
            }
            .attestation {
              vertical-align: middle;
              display: inline-block;
              background: #22b196;
              color: white;
              padding: 0px 2px;
              border-radius: 2px;
              font-size: 10px;
              margin-right: 5px;
            }
            .name {
              vertical-align: middle;
              display: inline-block;
            }
          }
          .productPrice {
            .productPriceTitle {
              color: #000000;
              font-size: 12px;
            }
            .productPriceNum {
              color: #ff625c;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
