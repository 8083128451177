import { isAPP, getPlatform } from "@environment/type";
import { commonAction } from "@environment/index";

export const initDeepLinks = () => {
    if (isAPP() && getPlatform() == 'ios') {

        function onDeviceReady() {

            //产品列表回调
            universalLinks.subscribe('openSearchPage', (eventData) => {
                //console.log('deeplinks data: ' + JSON.stringify(eventData));
                //window.location.href = "app://localhost/app/search?Order=1";
                commonAction('routerPush', {
                    isParams: true,
                    path: "search",
                    name: "search",
                    query: [
                        {
                            name: "ClassId",
                            value: eventData.params.ClassId,
                        },
                        {
                            name: "ClassName",
                            value: eventData.params.ClassName,
                        },
                        {
                            name: "SreachValues",
                            value: eventData.params.SreachValues,
                        },
                        {
                            name: "IsCouponProduct",
                            value: eventData.params.IsCouponProduct,
                        },
                        {
                            name: "IsBrandProduct",
                            value: eventData.params.IsBrandProduct,
                        },
                        {
                            name: "IsAttestationProduct",
                            value: eventData.params.IsAttestationProduct,
                        },
                        {
                            name: "Order",
                            value: eventData.params.Order,
                        },
                        {
                            name: "ATId",
                            value: eventData.params.ATId,
                        },
                        {
                            name: "Attname",
                            value: eventData.params.Attname,
                        },
                    ],
                })
            });

            //产品详情回调
            universalLinks.subscribe('openDetailPage', (eventData) => {
                //console.log('deeplinks data: ' + JSON.stringify(eventData));
                //window.location.href = "app://localhost/app/details?ProductNumber=" + eventData.params.ProductNumber + "&ShopId=" + eventData.params.ShopId;
                commonAction('routerPush', {
                    isParams: true,
                    path: "details",
                    name: "details",
                    query: [
                        {
                            name: "ProductNumber",
                            value: eventData.params.ProductNumber,
                        },
                        {
                            name: "ShopId",
                            value: eventData.params.ShopId,
                        }
                    ],
                })
            });

            //店铺详情回调
            universalLinks.subscribe('openStorePage', (eventData) => {
                //console.log('deeplinks data: ' + JSON.stringify(eventData));
                //window.location.href = "app://localhost/app/store?ShopId=32"
                commonAction('routerPush', {
                    isParams: true,
                    path: "store",
                    name: "store",
                    query: [
                        {
                            name: "ShopId",
                            value: eventData.params.ShopId,
                        },
                        {
                            name: "headerHide",
                            value: eventData.params.headerHide,
                        },
                    ],
                })
            });

            //支付回调
            universalLinks.subscribe('openPayCompletionPage', (eventData) => {
                //console.log('deeplinks data: ' + JSON.stringify(eventData));
                //window.location.href = "app://localhost/app/payCompletion?status=" + eventData.params.status + "&message=" + eventData.params.message;
                commonAction('routerPush', {
                    isParams: true,
                    path: "payCompletion",
                    name: "payCompletion",
                    query: [
                        {
                            name: "status",
                            value: eventData.params.status,
                        },
                        {
                            name: "message",
                            value: eventData.params.message,
                        }
                    ],
                })
            });

            //联系我们
            universalLinks.subscribe('openSupportPage', () => {
                commonAction('routerPush', {
                    isParams: false,
                    path: "user/support",
                    name: "support",
                    query: [],
                })
            });

            //隐私政策
            universalLinks.subscribe('openPrivacyPage', () => {
                commonAction('routerPush', {
                    isParams: false,
                    path: "terms/Privacy",
                    name: "privacy",
                    query: [],
                })
            });

            //个人用户服务协议
            universalLinks.subscribe('openAgreementPage', () => {
                commonAction('routerPush', {
                    isParams: false,
                    path: "terms/Agreement",
                    name: "agreement",
                    query: [],
                })
            });
        };


        document.addEventListener("deviceready", onDeviceReady, false);//cordova加载完成后触发事件
    }
}


export default {
    initDeepLinks,
}