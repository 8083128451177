import Vue from "vue";
import api from "@/api/api";
import { actionType } from "./action-type.js";
import { getRoute, isAPP, getPlatform } from "@environment/type";
import { typeName as vueTypeName } from "@environment/type/vue-until"; //vue 环境方法
import { openAction } from "@environment/model/remould-router";

export const commonAction = (actionName, param) => {
  let platform = getPlatform();

  if (actionName == "routerPush" || actionName == "routerReplace") {
    param.path = getRoute(true) + "/" + param.path;
  }
  // console.log(window.location.href);
  // console.log(isAPP());
  !param && (param = { isNodata: true });
  let stringParam = JSON.stringify(param);
  //console.log("方法名称=" + actionName + "字符串=" + stringParam);
  try {
    // 特殊处理showNotify,hideLoading,showLoading后改为执行vue环境的方法
    switch (actionName) {
      case "initApprate":
        vueTypeName.initApprate(param);
        break;
      case "initDeepLinks":
        vueTypeName.initDeepLinks(param);
        break;
      case "setAlias":
        vueTypeName.setAlias(param);
        break;
      case "initJpush":
        vueTypeName.initJpush(param);
        break;
      case "showNotify":
        vueTypeName.showNotify(param);
        break;
      case "hideNotify":
        vueTypeName.hideNotify(param);
        break;
      case "showDialog":
        vueTypeName.showDialog(param);
        break;
      case "hideDialog":
        vueTypeName.hideDialog(param);
        break;
      case "showToast":
        vueTypeName.showToast(param);
        break;
      case "hideToast":
        vueTypeName.hideToast(param);
        break;
      case "showLoading":
        vueTypeName.showLoading(param);
        break;
      case "hideLoading":
        vueTypeName.hideLoading(param);
        break;
      case "pop":
        vueTypeName.pop(param);
        break;
      case "routerPush":
        vueTypeName.routerPush(param);
        break;
      case "routerReplace":
        vueTypeName.routerReplace(param);
        break;
      case "picturePreview":
        vueTypeName.picturePreview(param);
        break;
      case "transmission":
        vueTypeName.transmission(param);
        break;
      case "backHome":
        vueTypeName.backHome(param);
        break;
      case "backUser":
        vueTypeName.backUser(param);
        break;
      case "newscenter":
        vueTypeName.newscenter(param);
        break;
      case "clickCopy": {
        if (isAPP()) {
          if (platform == 'ios') {
            window.cordova.plugins.clipboard.copy(param.text);
          }
          if (platform == 'android') {
            window.controller[actionName](param.text);
          }
          vueTypeName.showNotify({
            type: "success",
            titleMsg: param.alertText
          });
        } else {
          Vue.prototype.$copyText(param.text).then(() => {
            vueTypeName.showNotify({
              type: "success",
              titleMsg: param.alertText
            });
          });
        }
        break;
      }
      case "makePhoneCall": {
        openAction();
        break;
      }
      case "shareGoods": {
        if (isAPP()) {
          if (platform == 'ios') {
            navigator.share({
              title: param.name,
              text: param.name,
              url: param.shareUrl,
              image: api.formatImageUrl(param.image),
            })
          }
          if (platform == 'android') {
            window.controller[actionName](stringParam);
          }
        } else {
          openAction();
        }
        break;
      }
      case "openAlbum": {
        if (isAPP()) {
          window.controller[actionName](stringParam);
        } else {
          //openAlbum(stringParam);
          openAction();
        }
        break;
      }
      case "gotoPay": {
        if (isAPP()) {
          if (platform == 'ios') {
            window.cordova.InAppBrowser.open(param.url, '_system');
          } else {
            window.controller[actionName](stringParam);
          }
        } else {
          window.location.href = param.url;
        }
        break;
      }
      case "shareShop": {
        if (isAPP()) {
          if (platform == 'ios') {
            navigator.share({
              title: param.name,
              text: param.name,
              url: param.linkurl,
              image: api.formatImageUrl(param.image),
            })
          }
          if (platform == 'android') {
            window.controller[actionName](stringParam);
          }
        } else {
          openAction();
        }
        break;
      }
      case "exitapp": {
        if (isAPP()) {
          if (platform == 'ios') {
            navigator.app.exitApp();
          }
          if (platform == 'android') {
            window.controller[actionName]();
          }
        } else {
          openAction();
        }
        break;
      }
      default: {
        if (platform == 'ios') {
          navigator[actionName](stringParam);
        } else {
          window.controller[actionName](stringParam);
        }
      }
    }
  } catch (error) {
    console.log("ios或android环境没有匹配搭配方法名称" + actionName);
  }
};

export const envType = query => {
  const login = {
    userName: null,
    token: query.token
  };
  query.token && localStorage.setItem("auth", JSON.stringify(login));
  // 设置多语言
  // query.language && localStorage.setItem("language", query.language);
};
Vue.prototype.$commonEnv = {
  commonAction,
  actionType
  // env
};
export default {
  commonAction,
  envType
};
