import { isAPP, getPlatform } from "@environment/type";
import { Language } from "@/language/index";

export const initApprate = () => {
    if (isAPP() && getPlatform() == 'ios') {

        function onDeviceReady() {
            window.AppRate.setPreferences({
                usesUntilPrompt: 5,
                reviewType: {
                    ios: 'InAppBrowser',
                },
                storeAppURL: {
                    ios: '6723865168',
                },
                customLocale: {
                    title: Language.appRate.title,
                    message: Language.appRate.message,
                    cancelButtonLabel: Language.appRate.cancelButtonLabel,
                    laterButtonLabel: Language.appRate.laterButtonLabel,
                    rateButtonLabel: Language.appRate.rateButtonLabel,
                    yesButtonLabel: Language.appRate.yesButtonLabel,
                    noButtonLabel: Language.appRate.noButtonLabel,
                    appRatePromptTitle: Language.appRate.appRatePromptTitle,
                    feedbackPromptTitle: Language.appRate.feedbackPromptTitle,
                }
            });

            window.AppRate.promptForRating(false);
        }

        document.addEventListener("deviceready", onDeviceReady, false);//cordova加载完成后触发事件
    }
}


export default {
    initApprate
}