<template>
  <div class="cartBox">
    <van-pull-refresh v-model="refresh" @refresh="onRefresh">
      <div class="cartBackground" v-if="content && content.length > 0"></div>
      <div class="cartContent">
        <template v-if="content && content.length > 0">
          <h1>{{ $t.cart.title }}</h1>
          <p class="count">{{ $t.cart.total(count) }}</p>
          <span class="right-item" @click="toggleCheckBox">{{
            isManagementMode ? $t.cart.finish : $t.cart.manage
          }}</span>
          <div class="list">
            <product-section
              class="section"
              v-for="shop in content"
              :key="shop.shopID"
              :shop-id="shop.shopID"
              :shop-name="shop.shopName"
              :disabled="isShopAllDisabled(shop)"
              :selected="isShopAllSelected(shop)"
              @select="
                (v) => {
                  actionShopSelect(shop, v);
                }
              "
            >
              <product-cell
                v-for="product in shop.products"
                :key="product.UCId"
                :product="product"
                :isManagementMode="isManagementMode"
                @numberChanged="actionProductNumberDidChanged"
              ></product-cell>
            </product-section>
          </div>
        </template>
        <div class="null" v-else>
          <div class="title">
            <img src="~assets/img/shopping/shopping-cart.png" />
            <p>{{ $t.cart.empty }}</p>
          </div>
          <div class="btn" @click="goSearch">
            <span>{{ $t.cart.gogogo }}</span>
          </div>
        </div>
      </div>
      <div class="tool-bar" v-if="content && content.length > 0">
        <div class="all-select">
          <checkbox :disabled="disabledCheckAll" v-model="isAllSelected">
            <p class="checkbox-label">{{ $t.cart.checkAll }}</p>
          </checkbox>
        </div>
        <div class="right">
          <div class="price" v-show="!isManagementMode">
            <p class="detail">{{ $t.cart.excludeFreight }}</p>
            <p class="title">{{ $t.cart.payMoney }}</p>
            <p class="content">
              $
              <span>{{ sum | formatPrice }}</span>
            </p>
          </div>
          <div class="btn-pay" v-show="!isManagementMode" @click="actionSubmit">
            <span>{{ $t.cart.clearing }}</span>
          </div>
          <div
            class="btn-delete"
            v-show="isManagementMode"
            @click="actionDeleteProducts"
          >
            <span>{{ $t.cart.delete }}</span>
          </div>
        </div>
      </div>
      <div class="recommend">
        <p class="title">{{ $t.cart.recommend }}</p>
        <product-recommend></product-recommend>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import ProductSection from "./components/ProductSection";
import ProductCell from "./components/ProductCell";
import Checkbox from "./components/Checkbox";
import ProductRecommend from "@components/common/ProductRecommend.vue";
import { PullRefresh } from "vant";

export default {
  name: "Cart",
  components: {
    ProductSection,
    ProductCell,
    Checkbox,
    ProductRecommend,
    "van-pull-refresh": PullRefresh,
  },
  data() {
    return {
      refresh: false,
      isManagementMode: false,
      content: [],
      disabledCheckAll: false,
    };
  },
  computed: {
    isAllSelected: {
      get() {
        return this.content.every((shop) => {
          if (this.isManagementMode) {
            return shop.products.every((product) => {
              return product.isSelected;
            });
          } else {
            var products = shop.products.filter((state) => {
              return state.IsUser == 1;
            });
            if (!this.disabledCheckAll) {
              return products.every((product) => {
                return product.isSelected;
              });
            }
          }
        });
      },
      set(val) {
        for (const shop of this.content) {
          for (const product of shop.products) {
            if (this.isManagementMode) {
              product.isSelected = val;
            } else {
              if (product.IsUser) {
                product.isSelected = val;
              } else {
                product.isSelected = false;
              }
            }
          }
        }
      },
    },
    sum() {
      return this.content.reduce((value, shop) => {
        return shop.products.reduce((value, product) => {
          let price = product.isSelected
            ? product.NowMoney * product.Number
            : 0;
          return value + price;
        }, value);
      }, 0);
    },
    count() {
      return this.content.reduce((value, shop) => {
        return shop.products.reduce((value, product) => {
          let count = product.isSelected ? parseInt(product.Number) : 0;
          return value + count;
        }, value);
      }, 0);
    },
  },
  filters: {
    formatPrice(val) {
      var str = val.toFixed(2) + "";
      var intSum = str
        .substring(0, str.indexOf("."))
        .replace(/\B(?=(?:\d{3})+$)/g, ","); // 取到整数部分
      var dot = str.substring(str.length, str.indexOf(".")); // 取到小数部分搜索
      var ret = intSum + dot;
      return ret;
    },
  },
  mounted() {
    this.getShoppingCart();
  },
  methods: {
    setDefaultCheckAll() {
      if (this.isManagementMode) {
        this.disabledCheckAll = false;
        return;
      }
      var count = 0;
      for (var shop of this.content) {
        var products = shop.products.filter((state) => {
          return state.IsUser == 1;
        });
        count = count + products.length;
      }
      if (count == 0) {
        this.disabledCheckAll = true;
      } else {
        this.disabledCheckAll = false;
      }
    },
    onRefresh() {
      this.refresh = true;
      this.getShoppingCart(true)
        .then(() => {
          this.refresh = false;
        })
        .catch(() => {
          this.refresh = false;
        });
    },
    getShoppingCart(hideLoading = false) {
      return new Promise((resolve, reject) => {
        if (!hideLoading) {
          this.$commonMethod.showLoading();
        }
        this.$api.shopCar
          .loadShopCarByPage({
            PageIndex: 1,
            PageSize: 999,
          })
          .then((res) => {
            this.content = this.combineData(res.data);
            this.setDefaultCheckAll();
            if (!hideLoading) {
              this.$commonMethod.hideLoading();
            }
            resolve();
          })
          .catch((err) => {
            if (!hideLoading) {
              this.$commonMethod.hideLoading();
            }
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
            reject();
          });
      });
    },
    actionShopSelect(shop, isSelected) {
      if (this.isManagementMode) {
        for (const product of shop.products) {
          product.isSelected = isSelected;
        }
      } else {
        var products = shop.products.filter((state) => {
          return state.IsUser == 1;
        });
        for (const product of products) {
          product.isSelected = isSelected;
        }
      }
    },
    actionProductNumberDidChanged(product, num) {
      this.addCartNumber(product, num);
    },
    addCartNumber(product, num) {
      return new Promise((resolve) => {
        this.$commonMethod.showLoading();
        this.$api.shopCar
          .addShopCar({
            ShopId: product.ShopId,
            ProductNumber: product.ProductNumber,
            ProductModelStr: product.ProductModelStr,
            ProductModel: product.ProductModel,
            ShopMoney: product.ShopMoney,
            Number: num,
          })
          .then((_) => {
            this.$commonMethod.hideLoading();
            resolve(true);
          })
          .catch((err) => {
            this.$commonMethod.hideLoading();
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
          });
      });
    },
    actionDeleteProducts() {
      let arr = [];
      for (const shop of this.content) {
        for (const product of shop.products) {
          if (product.isSelected) {
            arr.push(product.UCId);
          }
        }
      }
      if (arr.length === 0) {
        this.$commonMethod.showNotify({
          type: "danger",
          titleMsg: this.$t.cart.selectItems,
        });
        return;
      }
      const ucids = arr.join("|");
      this.$commonMethod.showLoading();
      this.$api.shopCar
        .deleteShopCarByList({
          UCIdList: ucids,
        })
        .then((_) => {
          let has = true;
          do {
            let i, j;
            i = this.content.findIndex((shop) => {
              j = shop.products.findIndex((product) => {
                return product.isSelected;
              });
              return j !== -1;
            });
            if (i !== -1 && j !== -1) {
              this.content[i].products.splice(j, 1);
              if (this.content[i].products.length === 0) {
                this.content.splice(i, 1);
              }
            } else {
              has = false;
            }
          } while (has);
          this.$commonMethod.hideLoading();
        })
        .catch((err) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "error",
            titleMsg: err.message,
          });
        });
    },
    actionSubmit() {
      let arr = [];
      let offArr = [];
      for (const shop of this.content) {
        for (const product of shop.products) {
          if (product.isSelected) {
            if (product.IsUser == 1 && product.Number <= product.Inventory) {
              arr.push({
                UCId: product.UCId,
                ProductNumber: product.ProductNumber,
                Number: product.Number,
                ShopId: product.ShopId,
                ProductModel: product.ProductModel,
                ProductModelStr: product.ProductModelStr,
              });
            } else {
              offArr.push({
                ProductName: product.ProductName,
              });
            }
          }
        }
      }
      if (arr.length == 0 && offArr.length == 0) {
        this.$commonMethod.showNotify({
          type: "danger",
          titleMsg: this.$t.cart.selectItems,
        });
        return;
      }
      if (offArr.length > 0) {
        var prod = [];
        for (const item of offArr) {
          prod.push(item.ProductName);
        }

        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: JSON.stringify(prod) + " , " + this.$t.cart.productsOff,
          status: 400,
        });
        return;
      }
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "order",
        name: "order",
        query: [
          {
            name: "list",
            value: JSON.stringify(arr),
          },
        ],
      });
    },
    goSearch() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [],
      });
    },
    combineData(data) {
      let content = [];
      for (const product of data) {
        product.isSelected = false;
        let shop = content.find((v) => {
          return v.shopID === product.ShopId;
        });
        if (shop) {
          shop.products.push(product);
        } else {
          content.push({
            shopName: product.ShopName,
            shopID: product.ShopId,
            products: [product],
          });
        }
      }
      return content;
    },
    isShopAllSelected(shop) {
      if (this.isManagementMode) {
        return shop.products.every((product) => {
          return product.isSelected;
        });
      } else {
        var products = shop.products.filter((state) => {
          return state.IsUser == 1;
        });
        if (products.length > 0) {
          return products.every((product) => {
            return product.isSelected;
          });
        } else {
          return false;
        }
      }
    },
    isShopAllDisabled(shop) {
      if (!this.isManagementMode) {
        var products = shop.products.filter((state) => {
          return state.IsUser == 1;
        });
        if (products.length == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    toggleCheckBox() {
      this.isManagementMode = !this.isManagementMode;
      for (const shop of this.content) {
        shop.isSelected = false;
        for (const product of shop.products) {
          product.isSelected = false;
        }
      }
      this.setDefaultCheckAll();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/var";
$tool-bar-height: 50px;

.cartBox {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 40vw;
  .van-pull-refresh {
    overflow: unset;
  }
  .cartBackground {
    height: 200px;
    background-image: linear-gradient(to right, #71d284, #02aaa4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 175px;
    z-index: 1;
  }

  .cartContent {
    position: relative;
    z-index: 2;
    padding: 20px;
    .null {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 150px;
      .title {
        display: flex;
        align-items: center;
        color: #666;
        font-size: 18px;

        img {
          width: 32px;
          height: 32px;
        }

        p {
          margin: 0 0 0 10px;
        }
      }

      .btn {
        width: 110px;
        height: 30px;
        border: 1px solid #999;
        border-radius: 15px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #999;
      }
    }

    & > h1 {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      color: white;
    }

    & > .count {
      font-size: 12px;
      color: white;
      margin: 14px 0 0;
    }

    & > .right-item {
      position: absolute;
      font-size: 14px;
      color: white;
      right: 20px;
      top: 20px;
    }

    & > .list {
      margin-top: 14px;

      & > .section {
        margin-top: 15px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .tool-bar {
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    height: $tool-bar-height;
    left: 0;
    bottom: $tool-bar-height;
    background-color: white;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: env(safe-area-inset-bottom);
    border-top: 1px solid #f6f6f6;
    .all-select {
      display: flex;
      align-items: center;

      .checkbox-label {
        font-size: 14px;
        color: #999;
        margin: 0 0 0 10px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .price {
        display: flex;
        align-items: center;
        margin-right: 8px;

        p {
          margin: 0;
        }

        .detail {
          font-size: 11px;
          color: $-color-light-gray;
        }

        .title {
          font-size: 14px;
          color: black;
          margin-left: 5px;
        }

        .content {
          font-size: 12px;
          color: $-color-red;
          font-weight: bold;

          span {
            font-size: 18px;
          }
        }
      }

      .btn-pay,
      .btn-delete {
        width: 90px;
        line-height: 35px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
      }

      .btn-pay {
        background: linear-gradient(left, #71d283, #01aaa3);
      }

      .btn-delete {
        background-color: $-color-red;
      }
    }
  }
  .recommend {
    margin-top: 15px;

    > .title {
      font-size: 14px;
      color: #333;
      margin: 0 0 0 15px;
    }
  }
}
</style>
